import React, { Suspense, lazy } from 'react'
import { graphql } from 'gatsby'
import config from '../../data/SiteConfig'
import Gallery from '../components/Gallery'

const pageTitle = `Gallery - ${config.siteTitle}`
const Layout = lazy(() => import('../layout'))

const GalleryPage = ({ data = {}, location }) => {
  const { allInstagramContent } = data
  const posts = allInstagramContent?.edges || []

  return (
    <Suspense fallback={<span>Loading...</span>}>
      <Layout title={pageTitle} seoDescription="Pictures, Images, Posts" href={location.href}>
        <div className="container">
          <h1>Gallery</h1>
          <Gallery posts={posts} />
        </div>
      </Layout>
    </Suspense>
  )
}

export default GalleryPage

export const query = graphql`
  query InstagramPosts {
    allInstagramContent {
      edges {
        node {
          id
          caption
          media_url
          localImage {
            childImageSharp {
              gatsbyImageData(
                width: 500
                height: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

import React, { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ButtonLink } from './Button'

export default ({ posts = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(null)
  return (
    <div className="grid gallery">
      {posts?.edges?.map((item, index) => {
        const image = getImage(item.node.localImage)
        return (
          image && (
            <ButtonLink simpleLink className="picture" onClick={() => setCurrentIndex(index)}>
              <GatsbyImage
                image={image}
                key={item.node.id}
                alt={item.node.caption || 'Instagram Post'}
              />
            </ButtonLink>
          )
        )
      })}
      {currentIndex !== null && (
        <GalleryModal
          closeModal={() => setCurrentIndex(null)}
          findPrev={() => setCurrentIndex(currentIndex - 1)}
          findNext={() => setCurrentIndex(currentIndex + 1)}
          hasPrev={currentIndex > 0}
          hasNext={currentIndex + 1 < posts.edges.length}
          src={posts.edges[currentIndex]?.node.media_url}
          caption={posts.edges[currentIndex]?.node.caption}
          image={getImage(posts.edges[currentIndex]?.node.localImage)}
        />
      )}
    </div>
  )
}

const GalleryModal = ({
  closeModal,
  hasNext,
  hasPrev,
  findNext,
  findPrev,
  src,
  caption,
  image,
}) => {
  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown)
    return () => document.body.removeEventListener('keydown', handleKeyDown)
  }, [src])

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) closeModal()
    if (e.keyCode === 37 && hasPrev) findPrev()
    if (e.keyCode === 39 && hasNext) findNext()
  }

  if (!src) {
    return null
  }

  return (
    <>
      <div className="modal-overlay" onClick={closeModal}></div>
      <div className="modal">
        <div className="modal-body">
          <ButtonLink
            simpleLink
            className="modal-close"
            onClick={closeModal}
            onKeyDown={handleKeyDown}
          >
            &times;
          </ButtonLink>
          {hasPrev && (
            <ButtonLink
              simpleLink
              className="modal-prev"
              onClick={findPrev}
              onKeyDown={handleKeyDown}
            >
              &lsaquo;
            </ButtonLink>
          )}
          {hasNext && (
            <ButtonLink
              simpleLink
              className="modal-next"
              onClick={findNext}
              onKeyDown={handleKeyDown}
            >
              &rsaquo;
            </ButtonLink>
          )}
          <div className="position-absolute h-100 w-100" style={{ zIndex: 992 }}>
            <span onClick={findPrev} className="position-absolute w-50 h-100"></span>
            <span
              onClick={findNext}
              style={{ left: '50%' }}
              className="position-absolute w-50 h-100"
            ></span>
          </div>
          {!src.includes('.mp4') ? (
            <GatsbyImage image={image} alt={caption || 'Instagram Post'} />
          ) : (
            <video autoPlay controls width="100%">
              <source src={src} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          )}
        </div>
        <div className="caption">{caption}</div>
      </div>
    </>
  )
}
